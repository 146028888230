<template>
  <v-row class="mt-2">
    <v-col
      cols="12"
      md="6"
    >
      <!--<v-row>
        <v-col
          cols="12"
          md="12"
        >
          <strong class="mr-5">{{ $t('lbl.emailsReserve') }}</strong>
          <v-tooltip
            color="primary"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                {{ icons.mdiInformation }}
              </v-icon>
            </template>
            <span>
              <div>
                <div class="d-flex justify-start align-center">
                  <p class="mb-1 mt-2">
                    {{ $t('lbl.infoEmailsReserve') }}
                  </p>
                </div>
              </div>
            </span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mt-1 mx-2"
                fab
                dark
                x-small
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="addEmailAdminReserve"
              >
                <v-icon small>
                  {{ icons.mdiPlus }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('btn.insert') }}</span>
          </v-tooltip>
        </v-col>
      </v-row>-->
      <v-row v-if="idiomas.length > 0">
        <v-col
          cols="12"
          md="12"
        >
          <IdiomaOne
            v-for="(language, indLeng) in idiomas"
            :key="indLeng"
            :language="language"
            :pos="indLeng"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
  mdiInformation,
} from '@mdi/js'

import IdiomaOne from './IdiomaOne.vue'

export default {
  components: {
    IdiomaOne,
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      codeAfiliate: 'COD',
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
        mdiInformation,
      },
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),
      idiomas: [],
    }
  },
  computed: {
    ...mapState({
      languages: state => state.app.languages,
    }),
  },
  created() {
    this.fetchDataFromEndpoints()
  },
  methods: {
    ...mapMutations(['setLanguages']),
    async fetchDataFromEndpoints() {
      try {
        const [
          resItems,
        ] = await Promise.all([
          this.axios
            .post('languages/list', {}, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
        ])

        // eslint-disable-next-line no-empty
        if (resItems.data.success === false) { } else {
          this.idiomas = resItems.data.data.data
          const arr = []
          this.idiomas.forEach(element => {
            if (element.active) {
              arr.push(element)
            }
          })

          this.setLanguages(arr)
        }
      } catch (err) {
        console.error('Error fetching data:', err)
      }
    },
  },
}
</script>
<style scoped>
::v-deep .my-input .v-label,
::v-deep .my-input input {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
::v-deep .v-text-field__details {
  padding: 0 0 0 0;
}
</style>
